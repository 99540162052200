import React, { useRef, useState, useEffect } from "react"
import Slider from "react-slick"

const VoiceSlides = ({ quoteSlides }) => {

  const sortedQuotes = quoteSlides.sort((a, b) => Number(Date.parse(b.date)) - Number(Date.parse(a.date)))

  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()
  const slider1 = useRef(null)
  const slider2 = useRef(null)
  let slider2Dots = []

  useEffect(() => {
    setNav1(slider1.current)
    setNav2(slider2.current)
  }, [])

  const [imageIndex, setImageIndex] = useState(0)

  const slider1Settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 8000,
    cssEase: "ease",
    focusOnSelect: true,
    arrows: false,
    swipeToSlide: true,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const slider2Settings = {
    arrows: true,
    slidesToShow: 1,
    dots: true,
    pauseOnDotsHover: true,
    pauseOnHover: true
  }

  const quoteSlideNum = sortedQuotes.length

  for (let i = 1; i <= quoteSlideNum; i++) {
    slider2Dots.push(<div key={i}></div>)
  }

  if (quoteSlideNum === 3 || quoteSlideNum === 2) {
    sortedQuotes = sortedQuotes.concat(sortedQuotes)
    slider2Dots = slider2Dots.slice(0, 3)
  }

  return (
    <div style={{ padding: "40px 0 80px" }}>
      <div>
        <Slider {...slider1Settings} asNavFor={nav2} ref={slider1}>
          {sortedQuotes.map((pdf, i) => (
            <QuoteSlide
              key={i}
              quoteText={pdf.blurb}
              quoteName={pdf.href}
              quoteDate={pdf.date}
              className={
                i === imageIndex
                  ? "activeSlide quote-slide-box"
                  : "quote-slide-box"
              }
            />
          ))}
        </Slider>
        <div style={{ display: "block", width: 200, margin: "50px auto 0" }}>
          <Slider
            className="bottom-quote-slider"
            {...slider2Settings}
            asNavFor={nav1}
            ref={slider2}>
            {slider2Dots}
          </Slider>
        </div>
      </div>
    </div>
  )
}

function QuoteSlide(props) {
  return (
    <div key={props.key} className={props.className}>
      <div className="quote-slide-quote">
        <p>{props.quoteText}</p>
        <h6>{props.quoteDate}</h6>
        <a
          className="voices__card-btn"
          href={props.quoteName}
          title={`View the PDF`}
          target="_blank">
          Read More
        </a>
      </div>
    </div>
  )
}

export default VoiceSlides

import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import NuvoImage from "../components/NuvoImage"
import { MapCTA } from "../components/CTA"
import { Section, Container } from "../components/Containers"
import ImageStrip from "../components/ImageStrip/ImageStrip"
import VoiceSlides from "../components/Sliders/VoiceSlides"

const Voices = ({ pageContext, data, location }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { hero, mapCta, pdfSection, pdfSection2, imageStrip } = post
  const voices = data.allVoices.nodes
  const voicesHidden = voices.slice(0, 4)
  const [show, setShow] = useState(false)
  const voiceCount = voices.length

  return (
    <Layout customNavButton={post.customNavButton} className="voices-query">
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        schemaPage={null}
      />

      <NuvoImage publicId={hero.image} wrapperClassName="is-hidden-mobile" />
      <NuvoImage
        publicId={hero.imageMobile}
        wrapperClassName="is-hidden-tablet"
      />

      <Section>
        <Container small>
          <h1 className="has-text-centered" id="community-voices">
            Community Voices
          </h1>

          {show ? (
            <div>
              <div className="voices-query__container grid-even-2">
                {voices.map((item, i) => (
                  <div key={i} className="voices__card">
                    <h3>{item.query.name}</h3>
                    <p>{item.query.blurb}</p>
                    <Link
                      className="voices__card-btn"
                      to={`/${item.title}`}
                      title={`Read the full "${item.query.name}" article`}>
                      Read More
                    </Link>
                  </div>
                ))}
              </div>
              <div className="read-more-btn">
                <a className="standard-button" onClick={() => setShow(!show)}>
                  View Less
                </a>
                <p className="voice-count">
                  Showing {voiceCount}/{voiceCount}
                </p>
              </div>
            </div>
          ) : (
            <div>
              <div className="voices-query__container--hidden grid-even-2">
                {voicesHidden.map((item, i) => (
                  <div key={i} className="voices__card">
                    <h3>{item.query.name}</h3>
                    <p>{item.query.blurb}</p>
                    <Link
                      className="voices__card-btn"
                      to={`/${item.title}`}
                      title={`Read the full "${item.query.name}" article`}>
                      Read More
                    </Link>
                  </div>
                ))}
              </div>
              <div className="read-more-btn">
                <a
                  className="standard-button"
                  onClick={() => setShow(!show)}
                  href="#community-voices">
                  View All
                </a>
                <p className="voice-count">Showing 4/{voiceCount}</p>
              </div>
            </div>
          )}
        </Container>
      </Section>

      <ImageStrip images={imageStrip.slice(4, 8)} />

      <Section colorBack className="public-comments">
        <Container small>
          <h3>{pdfSection.heading}</h3>

          {/* <div className="voices-query__container voices-query__pdfs grid-even-2">
            {pdfSection.pdfs.map((item, i) => (
              <div key={i} className="voices__card">
                <p>{item.blurb}</p>
                <a
                  className="voices__card-btn"
                  href={item.href}
                  title={`View the PDF`}
                  target="_blank">
                  Read More
                  </a>
                  </div>
                  ))}
                </div> */}
        </Container>
        <VoiceSlides quoteSlides={pdfSection.pdfs} />
      </Section>

      {/* <Section>
        <Container small>
          <h3>{pdfSection2.heading}</h3>

          <div className="voices-query__container voices-query__pdfs grid-even-2">
            {pdfSection2.pdfs.map((item, i) => (
              <div key={i} className="voices__card white">
                <p>{item.blurb}</p>
                <a
                  className="voices__card-btn"
                  href={item.href}
                  title={`View the PDF`}
                  target="_blank">
                  Read More
                </a>
              </div>
            ))}
          </div>
        </Container>
      </Section> */}

      <ImageStrip images={imageStrip.slice(0, 4)} />

      <MapCTA
        image={mapCta.image}
        //text={mapCta.text}
        button={mapCta.button}
      />
    </Layout>
  )
}

Voices.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object
}

export const voicesPageQuery = graphql`
  query voicesQuery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        hero {
          image
          imageMobile
        }
        heading
        pdfSection {
          heading
          pdfs {
            blurb
            href
            date
          }
        }
        pdfSection2 {
          heading
          pdfs {
            blurb
            href
          }
        }
        imageStrip
        mapCta {
          image
          text
          button {
            buttonText
            appearance
            destination
            href
          }
        }
      }
    }
    allVoices: allVoicesJson(
      sort: { order: DESC, fields: createdAt }
      filter: { hasThisPage: { eq: true } }
    ) {
      nodes {
        query {
          name
          blurb
        }
        title
      }
    }
  }
`

export default Voices
